import React from "react";
import { Link } from "react-router-dom";
import Footer from "../Constant/Footer/Footer";
import Header from "../Constant/Header/Header";
import styles from "./PageNotFound.module.css"; // Import the CSS module

const PageNotFound = () => {
    return (
        <div>
            <Header />
            <div className={styles.container}>
                <h2 className={styles.heading}>404 - Page Not Found</h2>
                <p className={styles.message}>The page you are looking for does not exist.</p>
                <p className={styles.message}>
                    Go to Homepage <Link to="/" className={styles.link}>Home</Link>
                </p>
            </div>
            <Footer />
        </div>
    );
};

export default PageNotFound;
