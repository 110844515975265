import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
const MetaTags = ({ title }) => {
  return (
    <HelmetProvider>
        <Helmet>
          <title>{title}</title>
          <meta name="title" content={title} />
          <meta name="description" content="Discover Bawa Chicken Kirti Nagar, your go-to spot for tasty chicken dishes. Explore the Bawa Chicken menu and find Bawa Chicken near you for a delightful dining experience!" />
          <meta name="keywords" content="bawa chicken kirti nagar, bawa chicken, bawa chicken menu, bawa chicken kirti nagar menu, bawa chicken near me" />
          <meta name="robots" content="index, follow" />
          {/* Open Graph Meta Tags (for Social Sharing) */}
          <meta property="og:title" content={{title}} />
          <meta property="og:description" content="Discover Bawa Chicken Kirti Nagar, your go-to spot for tasty chicken dishes. Explore the Bawa Chicken menu and find Bawa Chicken near you for a delightful dining experience!" />
          <meta property="og:url" content="https://bawachicken.in/" />
        </Helmet>
      </HelmetProvider>
  );
};
export default MetaTags;