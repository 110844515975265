import React from 'react';
import styles from './TermsAndCondition.module.css';
import Header from '../Constant/Header/Header';
import Footer from '../Constant/Footer/Footer';
import MetaTags from '../Constant/MetaTags';

const TermsAndCondition = () => {
    return (
        <div>
            <MetaTags title="Terms & Conditions | Bawa Chicken"/>
            <Header />
            <div className={styles.tncSection}>
                <div className={styles.tncOverlay}></div>
                <div className={styles.tncText}>TERMS & CONDITIONS</div>
            </div>

            <div className={styles.container}>
                <h2 className={styles.sectionTitle}>1. Acceptance of Terms</h2>
                <p className={styles.sectionContent}>
                    By using the Site, you agree to these Terms and our Privacy Policy. If you do not agree with any part of these Terms, you must not use the Site.
                </p>

                <h2 className={styles.sectionTitle}>2. Changes to Terms</h2>
                <p className={styles.sectionContent}>
                    We may update these Terms from time to time. We will notify you of any changes by posting the new Terms on this page. You are advised to review these Terms periodically for any changes. Changes to these Terms are effective when they are posted on this page.
                </p>

                <h2 className={styles.sectionTitle}>3. Use of the Site</h2>
                <p className={styles.sectionContent}>
                    You agree to use the Site only for lawful purposes and in accordance with these Terms. You agree not to use the Site:
                    <ul>
                        <li>In any way that violates any applicable local, national, or international law or regulation.</li>
                        <li>To engage in any conduct that restricts or inhibits anyone's use or enjoyment of the Site, or which, as determined by us, may harm Bawa Chicken or users of the Site or expose them to liability.</li>
                    </ul>
                </p>

                <h2 className={styles.sectionTitle}>4. Account Security</h2>
                <p className={styles.sectionContent}>
                    If you create an account on the Site, you are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account. You agree to notify us immediately of any unauthorized use of your account or any other breach of security.
                </p>

                <h2 className={styles.sectionTitle}>5. Ordering Food and Other Items</h2>
                <p className={styles.sectionContent}>
                    <strong>Placing Orders:</strong> To place an order, you must sign in to your account on the Site. You can browse our selection of vegetarian and non-vegetarian food, select your desired products, and complete the order process.
                    <br />
                    <strong>Order Confirmation:</strong> After placing an order, you will receive an order confirmation email with details of your purchase. It is your responsibility to ensure that the information provided is accurate.
                    <br />
                    <strong>Delivery:</strong> We aim to deliver your order within 1 day of placing it, depending on your location and availability. We are not responsible for delays caused by factors beyond our control.
                </p>

                <h2 className={styles.sectionTitle}>6. Refund Policy</h2>
                <p className={styles.sectionContent}>
                    We strive to deliver the best quality food. However, if you are not satisfied with your order due to quality issues, you may request a refund within 24 hours of receiving your order. Refunds are processed on a case-by-case basis and at the discretion of the management. Please contact our customer service team for assistance.
                </p>

                <h2 className={styles.sectionTitle}>7. Intellectual Property</h2>
                <p className={styles.sectionContent}>
                    The content on the Site, including but not limited to text, graphics, logos, images, and software, is the property of Bawa Chicken or its licensors and is protected by intellectual property laws. You may not use, reproduce, distribute, or modify any content from the Site without our prior written consent.
                </p>

                <h2 className={styles.sectionTitle}>8. Limitation of Liability</h2>
                <p className={styles.sectionContent}>
                    To the fullest extent permitted by law, Bawa Chicken shall not be liable for any indirect, incidental, special, or consequential damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from:
                    <ul>
                        <li>Your use or inability to use the Site.</li>
                        <li>Any unauthorized access to or use of our servers and/or any personal information stored therein.</li>
                        <li>Any interruption or cessation of transmission to or from the Site.</li>
                        <li>Any bugs, viruses, trojans, or the like that may be transmitted to or through the Site by any third party.</li>
                    </ul>
                </p>

                <h2 className={styles.sectionTitle}>9. Governing Law</h2>
                <p className={styles.sectionContent}>
                    These Terms shall be governed by and construed in accordance with the laws of India. Any legal action or proceeding arising out of or related to these Terms shall be brought exclusively in the courts located in New Delhi, India, and you consent to the jurisdiction of such courts.
                </p>

                <h2 className={styles.sectionTitle}>10. Shipping and Delivery Policy</h2>
                <p className={styles.sectionContent}>
                    <strong>Delivery Areas:</strong> We currently deliver to selected areas within New Delhi. Please enter your delivery address at checkout to verify if we can deliver to your location.
                    <br />
                    <strong>Delivery Costs:</strong> Delivery fees will be calculated based on your delivery location and added to the total order amount at checkout.
                    <br />
                    <strong>Delivery Time:</strong> We strive to deliver your order within 1 day of placing it. Delivery times may vary depending on location and availability.
                    <br />
                    <strong>Order Tracking:</strong> Once your order has been dispatched, you will receive a tracking number to monitor the status of your delivery.
                    <br />
                    <strong>Delivery Issues:</strong> If you encounter any issues with your delivery, please contact our customer service team immediately for assistance.
                </p>

                <h2 className={styles.sectionTitle}>11. Contact Us</h2>
                <p className={styles.sectionContent}>
                    If you have any questions regarding these Terms or our services, feel free to reach out to us at:
                    <br />
                    <strong>Bawa Chicken</strong>
                    <br />
                    Website: https://www.bawachicken.in
                </p>
            </div>
            <Footer />
        </div>
    );
};

export default TermsAndCondition;
