import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const PrivateRoute = ({ children, allowedRoles }) => {
    const navigate = useNavigate();
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    const checkUserToken = () => {
        const userToken = localStorage.getItem('tokenId');
        const userRole = localStorage.getItem('role');

        if (!userToken || userToken === 'undefined') {
            setIsLoggedIn(false);
            return navigate('/');
        }

        if (!allowedRoles.includes(parseInt(userRole))) {
            setIsLoggedIn(false);
            return navigate('/');
        }

        setIsLoggedIn(true);
    }

    useEffect(() => {
        checkUserToken();
    }, [isLoggedIn]);

    return (
        <React.Fragment>
            {isLoggedIn ? children : null}
        </React.Fragment>
    );
}

export default PrivateRoute;
