import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";

const firebaseConfig = {
    apiKey: "AIzaSyCOvmxdk6ieCd4ZqxxfGHj6ucdvBw-JfuE",
    authDomain: "order-food-online-1977c.firebaseapp.com",
    projectId: "order-food-online-1977c",
    storageBucket: "order-food-online-1977c.appspot.com",
    messagingSenderId: "981130164714",
    appId: "1:981130164714:web:8baf453b6b859b47b71a93",
    measurementId: "G-PDXEQ5G88L"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);