import React, { useState, useEffect, useRef } from 'react';
import { fetchMenuCategories } from './fetchMenuCategories';
import Login from '../Login/Login';
import styles from './Menu.module.css';
import Header from '../Constant/Header/Header';
import Footer from '../Constant/Footer/Footer';
import veg from '../Images/veg.png';
import nonveg from '../Images/nonveg.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import ViewCart from '../ViewCart/ViewCart';
import axiosInstance from '../../api';
import { useNavigate } from 'react-router-dom';
import { FaSearch } from 'react-icons/fa';
import Variant from './Variant';
import MetaTags from '../Constant/MetaTags';

const Menu = () => {
  const [categories, setCategories] = useState({});
  const [selectedFoodType, setSelectedFoodType] = useState('ALL');
  const [cartItems, setCartItems] = useState(() => {
    const savedCartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
    return savedCartItems;
  });
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [quantities, setQuantities] = useState(() => {
    const savedQuantities = JSON.parse(localStorage.getItem('quantities')) || {};
    return savedQuantities;
  });
  const [showViewCart, setShowViewCart] = useState(false);
  const [expandedCategories, setExpandedCategories] = useState({});
  const [isRestaurantOpen, setIsRestaurantOpen] = useState(true);
  const [showClosedPopup, setShowClosedPopup] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [noItemsFound, setNoItemsFound] = useState(false);
  const [showVariantPopup, setShowVariantPopup] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [clientType, setClientType] = useState(0);
  const categoryRefs = useRef({});
  const [category, setCategory] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    const role = localStorage.getItem('role');
    setIsAdmin(role === '1');
  }, []);

  useEffect(() => {
    const token = localStorage.getItem('tokenId');
    if (token) {
      setIsAuthenticated(true);
    }
  }, []);


  useEffect(() => {
    const fetchRestaurantStatus = async () => {
      try {
        const response = await axiosInstance.get(process.env.REACT_APP_MASTER_CLIENT, {
          params: { client: process.env.REACT_APP_CLIENT },
        });
        setIsRestaurantOpen(response.data.responseObject);
      } catch (error) {
        console.error('Failed to fetch restaurant status', error);
        setIsRestaurantOpen(false);
      }
    };
    fetchRestaurantStatus();
  }, []);

  useEffect(() => {
    if (!isRestaurantOpen) {
      setShowClosedPopup(true);
    }
  }, [isRestaurantOpen]);

  const handleClosePopup = () => {
    setShowClosedPopup(false);
    navigate('/'); // Navigate to the home page
  };


  const fetchCategories = async () => {
    try {
      const data = await fetchMenuCategories(selectedFoodType === 'ALL' ? '' : selectedFoodType, searchTerm);

      if (data.responseObject.length > 0) {
        setClientType(data.responseObject[0].clientType);
      }

      const groupedItems = data.responseObject.reduce((acc, item) => {
        if (!acc[item.categoryName]) {
          acc[item.categoryName] = [];
        }
        acc[item.categoryName].push(item);
        return acc;
      }, {});

      // Initialize expandedCategories with all categories expanded
      setExpandedCategories(Object.keys(groupedItems).reduce((acc, key) => {
        acc[key] = true; // Expand all categories
        return acc;
      }, {}));

      if (searchTerm.trim()) {
        setNoItemsFound(Object.keys(groupedItems).length === 0); // Check if no items are found
      } else {
        setNoItemsFound(false);
      }

      setCategories(groupedItems);
    } catch (error) {
      console.error('Failed to fetch menu categories');
    }
  };


  useEffect(() => {
    fetchCategories();
  }, [selectedFoodType, searchTerm]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleCartClick = () => {
    if (!isAuthenticated) {
      setShowLogin(true);
    } else {
      setShowViewCart(true);
      setShowVariantPopup(false);
    }
  };

  const handleLoginSuccess = () => {
    setIsAuthenticated(true);
    setShowLogin(false);
    window.location.reload();
  };

  const handleCloseVariantPopup = () => {
    setShowVariantPopup(false);
    setSelectedItem(null);
  };

  const handleQuantityChange = (itemId, delta, categoryName, variantId = null) => {
    const categoryItems = categories[categoryName] || [];
    const item = categoryItems.find((item) => item.itemId === itemId);

    if (item.item.variants.length > 0 && !variantId) {
      setSelectedItem(item);
      setShowVariantPopup(true);
    }

    else if (variantId) {
      const key = `${itemId}-${variantId}`;

      // Update quantities
      setQuantities((prevQuantities) => {
        const newQuantities = { ...prevQuantities };
        newQuantities[key] = (newQuantities[key] || 0) + delta;
        if (newQuantities[key] < 0) newQuantities[key] = 0;

        // Update cart items
        setCartItems((prevCartItems) => {
          if (newQuantities[key] === 0) {
            // Remove the item if quantity is zero
            return prevCartItems.filter((item) => {
              return !(item.itemId === itemId && item.variantId === variantId);
            });
          } else {
            // Check if the item exists in the cart
            const existingItem = prevCartItems.find((item) => item.itemId === itemId && item.variantId === variantId);

            if (existingItem) {
              // Update the existing item quantity
              return prevCartItems.map((item) =>
                (item.itemId === itemId && item.variantId === variantId) ? { ...item, quantity: newQuantities[key], categoryName } : item
              );
            } else {
              // Add new item to the cart
              const variant = item.item.variants.find(variant => variant.id === variantId);
              return [...prevCartItems, { ...item, variantId, variantName: variant.variant, variantDiscountPrice: variant.discountPrice, variantOriginalPrice: variant.originalPrice, quantity: newQuantities[key], categoryName }];
            }
          }
        });

        return newQuantities;
      });
    }
    else {
      setQuantities((prevQuantities) => {
        const newQuantities = { ...prevQuantities };
        newQuantities[itemId] = (newQuantities[itemId] || 0) + delta;
        if (newQuantities[itemId] < 0) newQuantities[itemId] = 0;

        setCartItems((prevCartItems) => {
          if (newQuantities[itemId] === 0) {
            return prevCartItems.filter((item) => item.itemId !== itemId);
          } else {
            const existingItem = prevCartItems.find((item) => item.itemId === itemId);
            if (existingItem) {
              return prevCartItems.map((item) =>
                item.itemId === itemId ? { ...item, quantity: newQuantities[itemId], categoryName } : item
              );
            } else {

              return [...prevCartItems, { ...item, quantity: newQuantities[itemId], categoryName }];
            }
          }
        });

        return newQuantities;
      });
    }
  };

  const handleRemoveItem = (itemId, variantId = null) => {

    if (variantId) {
      const key = `${itemId}-${variantId}`;

      setCartItems((prevCartItems) => {
        return prevCartItems.filter((item) => {
          // Remove the item if itemId matches and variantId matches
          return !(item.itemId === itemId && item.variantId === variantId);
        });
      });

      setQuantities((prevQuantities) => {
        const newQuantities = { ...prevQuantities };
        delete newQuantities[key];
        return newQuantities;
      });

    } else {
      setCartItems((prevCartItems) => prevCartItems.filter((item) => item.itemId !== itemId));
      setQuantities((prevQuantities) => {
        const newQuantities = { ...prevQuantities };
        delete newQuantities[itemId];
        return newQuantities;
      });
    }

  };

  const handleCloseViewCart = () => {
    setShowViewCart(false);
  };

  const toggleCategory = (category) => {
    setExpandedCategories((prev) => ({
      ...prev,
      [category]: !prev[category],
    }));
  };

  const getCategoryItemsWithQuantities = () => {
    const newCategories = { ...categories };
    Object.keys(newCategories).forEach((category) => {
      newCategories[category] = newCategories[category].map((item) => ({
        ...item,
        quantity: quantities[item.itemId] || 0,
      }));
    });
    return newCategories;
  };

  const categoriesWithQuantities = getCategoryItemsWithQuantities();

  useEffect(() => {
    localStorage.setItem('cartItems', JSON.stringify(cartItems));
  }, [cartItems]);

  useEffect(() => {
    localStorage.setItem('quantities', JSON.stringify(quantities));
  }, [quantities]);

  useEffect(() => {
    // Add or remove no-scroll class based on the visibility of any popup
    const hasPopupOpen = showViewCart || showLogin || showClosedPopup || showVariantPopup;
    if (hasPopupOpen === true) {
      document.body.classList.add(styles.noScroll);
    } else {
      document.body.classList.remove(styles.noScroll);
    }
    return () => {
      document.body.classList.remove(styles.noScroll); // Clean up class when component unmounts
    };
  }, [showViewCart, showLogin, showClosedPopup, showVariantPopup]);

  const toggleItemStatus = async (itemId, currentStatus) => {
    const newStatus = currentStatus === 1 ? 0 : 1;

    try {
      await axiosInstance.post(process.env.REACT_APP_ITEM_STATUS_UPDATE, {
        itemId: itemId,
        status: newStatus,
      });

      setCategories((prevCategories) => {
        const updatedCategories = { ...prevCategories };

        // Find and update the item status
        Object.keys(updatedCategories).forEach((category) => {
          updatedCategories[category] = updatedCategories[category].map((item) => {
            if (item.itemId === itemId) {
              return { ...item, item: { ...item.item, status: newStatus } };
            }
            return item;
          });
        });

        return updatedCategories;
      });
    } catch (error) {
      console.error('Error updating item status:', error);
    }
  };

  useEffect(() => {
    const formattedCategory = category ? category : null;
    if (formattedCategory && categoryRefs.current[formattedCategory]) {
      categoryRefs.current[formattedCategory].scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [category]);
  const handleCategorySelect = (categoryName) => {
    setCategory(categoryName);
  };

  return (
    <div>
      <MetaTags title="Bawa Chicken Kirti Nagar Menu" />
      <Header />
      <div className={styles.menuSection}>
        <div className={styles.menuOverlay}></div>
        <div className={styles.menuText}>MENU</div>
      </div>
      <div className={styles.menuContainer}>
        <div className={styles.filters}>
          {clientType === 3 && (<><button
            className={selectedFoodType === 'ALL' ? styles.selected : ''}
            onClick={() => setSelectedFoodType('ALL')}
          >
            All
          </button>
            <button
              className={selectedFoodType === 'VEG' ? styles.selectedVeg : ''}
              onClick={() => setSelectedFoodType('VEG')}
            >
              Veg
            </button>
            <button
              className={selectedFoodType === 'NON_VEG' ? styles.selectedNonVeg : ''}
              onClick={() => setSelectedFoodType('NON_VEG')}
            >
              Non Veg
            </button>
          </>)}
          <div className={styles.searchBarContainer}>
            <input
              type="text"
              className={styles.searchBar}
              placeholder="Search for items..."
              value={searchTerm}
              onChange={handleSearchChange}
            />
            <FaSearch className={styles.searchIcon} />
          </div>
          {/* Dropdown for category selection */}
          <select
            className={styles.categorySelect}
            onChange={(e) => handleCategorySelect(e.target.value)}
          >
            <option value="">Select Category</option>
            {Object.keys(categories).map((categoryName) => (
              <option key={categoryName} value={categoryName}>
                {categoryName}
              </option>
            ))}
          </select>
        </div>

        {showVariantPopup && selectedItem && (
          <Variant
            cartItems={cartItems}
            item={selectedItem}
            handleQuantityChange={handleQuantityChange}
            onClose={handleCloseVariantPopup}
          />
        )}

        {noItemsFound ? (
          <div className={styles.noItemsFound}>No items found</div>
        ) : (Object.keys(categoriesWithQuantities).map((category) => (
          <div key={category} ref={(el) => (categoryRefs.current[category] = el)} className={styles.category}>
            <h2 onClick={() => toggleCategory(category)} className={styles.categoryHeader}>
              {category}
              <FontAwesomeIcon
                icon={expandedCategories[category] ? faChevronUp : faChevronDown}
                className={styles.chevronIcon}
              />
            </h2>
            {expandedCategories[category] && (
              <div className={styles.items}>
                {categoriesWithQuantities[category].map((item) => (
                  <div key={item.itemId} className={`${styles.itemCard} ${item.item.status === 0 && !isAdmin ? styles.disabled : ''}`}>
                    {item.item.status === 0 && !isAdmin && (
                      <div className={styles.outOfStock}>Out of Stock</div>
                    )}
                    <img src={item.item.imageUrl} alt={item.item.item} className={styles.itemImage} />
                    <div className={styles.itemInfo}>
                      <div className={styles.itemName}>
                        <img
                          src={item.item.foodType === 'VEG' ? veg : nonveg}
                          alt={item.item.foodType}
                          className={styles.foodTypeIcon}
                        />
                        <h3>{item.item.item}</h3>
                      </div>
                      <p className={styles.itemDescription}>{item.item.itemDescription}</p>
                      <p>
                        {(item.item.originalPrice) && <span className={styles.originalPrice}>₹{item.item.originalPrice}</span>}
                        <span className={styles.discountPrice}>₹{item.item.discountPrice}</span>
                      </p>
                      <div className={styles.quantitySelector}>
                        <button onClick={() => handleQuantityChange(item.itemId, -1, category)}>-</button>
                        <span>
                          {item.item.variants.length > 0
                            ? item.item.variants.reduce(
                              (total, variant) =>
                                total + (quantities[`${item.itemId}-${variant.id}`] || 0),
                              0
                            )
                            : quantities[item.itemId] || 0}
                        </span>

                        <button onClick={() => handleQuantityChange(item.itemId, 1, category)}>+</button>
                        {isAdmin && <label className={styles.switch}>
                          <input
                            type="checkbox"
                            checked={item.item.status === 1}
                            onChange={() => toggleItemStatus(item.item.id, item.item.status)}
                          />
                          <span className={`${styles.slider} ${styles.round}`}></span>
                        </label>}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        )))}

        {Object.values(quantities).some((quantity) => quantity > 0) && !showViewCart && !showVariantPopup && (
          <button className={styles.cartButton} onClick={handleCartClick}>
            <div className={styles.cartIcon}>
              <span>{Object.values(quantities).reduce((a, b) => a + b, 0)} item(s)</span>
              <FontAwesomeIcon icon={faShoppingCart} />
            </div>
            <div className={styles.cartText}>
              <span>View Cart</span>
            </div>
          </button>
        )}

        <Login
          show={showLogin}
          handleClose={() => setShowLogin(false)}
          handleLogin={handleLoginSuccess}
        />

        {showViewCart && (
          <div className={styles.modalOverlay}>
            <div className={styles.modal}>
              <ViewCart
                cartItems={cartItems}
                handleQuantityChange={handleQuantityChange}
                handleRemoveItem={handleRemoveItem}
                handleCloseViewCart={handleCloseViewCart}
              />
              <button className={styles.closeModal} onClick={handleCloseViewCart}>
                Close
              </button>
            </div>
          </div>
        )}

        {showClosedPopup && (
          <div className={styles.closedPopup}>
            <div className={styles.popupContent}>
              <h2>We Are Currently Closed</h2>
              <p>Please come back tomorrow during our regular hours. Thank you!</p>
              <button onClick={handleClosePopup}>OK</button>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default Menu;
