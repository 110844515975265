import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import Logout from '../Logout/Logout';
import styles from './ProfileMenu.module.css';

const ProfileMenu = ({ isAuthenticated, handleLogout }) => {
    const [profileMenuOpen, setProfileMenuOpen] = useState(false);
    const fullName = localStorage.getItem('fullName');

    const toggleProfileMenu = () => {
        setProfileMenuOpen(!profileMenuOpen);
    };

    return (
        isAuthenticated && (
            <li className={`${styles.profileMenu} ${profileMenuOpen ? styles.open : ''}`}>
                <a href="#" onClick={toggleProfileMenu}>
                    <FontAwesomeIcon icon={faUser} /> {fullName} <span>&#x25BC;</span>
                </a>
                {profileMenuOpen && (
                    <ul className={styles.dropdownMenu}>
                        <li><Logout handleLogout={handleLogout} /></li>
                    </ul>
                )}
            </li>
        )
    );
};

export default ProfileMenu;
