import React, { useEffect, useState } from 'react';
import axiosInstance from '../../api'; // Adjust path as needed
import styles from './DeliveryBoy.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faTrashAlt, faPlus, faUserPlus } from '@fortawesome/free-solid-svg-icons'; // Import the user-plus icon
import Header from '../Constant/Header/Header';
import Footer from '../Constant/Footer/Footer';

const DeliveryBoy = () => {
    const [deliveryBoys, setDeliveryBoys] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [newDeliveryBoy, setNewDeliveryBoy] = useState({ fullName: '', mobilenumber: '' });
    const [errors, setErrors] = useState({ fullName: '', mobilenumber: '' });
    const [isSaving, setIsSaving] = useState(false); // New state to manage the save button loading

    useEffect(() => {
        const fetchDeliveryBoys = async () => {
            const payload = [
                { key: "ROLE", value: '3' }
            ];
            try {
                const response = await axiosInstance.post(
                    `${process.env.REACT_APP_USER}`,
                    payload
                );
                if (response.data.responseStatus && response.data.responseObject.content) {
                    setDeliveryBoys(response.data.responseObject.content);
                }
            } catch (error) {
                console.error('Error fetching delivery boys:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchDeliveryBoys();
    }, []);

    const handleDelete = async (id) => {
        try {
            await axiosInstance.post(
                `${process.env.REACT_APP_USER_DELETE}` + `?id=${id}`,
                {},
            );
            // Remove deleted delivery boy from state
            setDeliveryBoys(deliveryBoys.filter(boy => boy.id !== id));
        } catch (error) {
            console.error('Error deleting delivery boy:', error);
        }
    };

    const openPopup = () => {
        setIsPopupOpen(true);
        document.body.style.overflow = 'hidden'; // Disable background scrolling
    };

    const closePopup = () => {
        setIsPopupOpen(false);
        document.body.style.overflow = 'auto'; // Enable background scrolling
        setNewDeliveryBoy({ fullName: '', mobilenumber: '' });
        setErrors({ fullName: '', mobilenumber: '' }); // Clear errors
    };

    const validate = () => {
        let isValid = true;
        const newErrors = { fullName: '', mobilenumber: '' };

        if (!newDeliveryBoy.fullName.trim()) {
            newErrors.fullName = 'Full Name is required';
            isValid = false;
        }

        if (!newDeliveryBoy.mobilenumber.trim()) {
            newErrors.mobilenumber = 'Mobile Number is required';
            isValid = false;
        } else if (!/^\d{10}$/.test(newDeliveryBoy.mobilenumber)) {
            newErrors.mobilenumber = 'Please enter a valid mobile number';
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };

    const handleSave = async () => {
        if (!validate()) return;

        setIsSaving(true); // Set saving state to true
        try {
            const payload = {
                ...newDeliveryBoy,
                client: process.env.REACT_APP_CLIENT, // Adjust client as needed
                role: 3,
            };
            await axiosInstance.post(
                `${process.env.REACT_APP_USER_CREATE}`,
                payload
            );
            // Fetch the updated list after saving the new delivery boy
            setDeliveryBoys([...deliveryBoys, payload]);
            closePopup();
        } catch (error) {
            console.error('Error saving delivery boy:', error);
        } finally {
            setIsSaving(false); // Set saving state to false
        }
    };

    const handleChange = (e) => {
        setNewDeliveryBoy({
            ...newDeliveryBoy,
            [e.target.name]: e.target.value
        });
    };

    return (
        <div>
            <Header />
            <div className={styles.deliveryBoySection}>
                <div className={styles.deliveryBoyOverlay}></div>
                <div className={styles.deliveryBoyText}>DELIVERY BOY</div>
            </div>
            <div className={styles.container}>
                <div className={styles.addButtonContainer}>
                    <button className={styles.addButton} onClick={openPopup}>
                        <FontAwesomeIcon icon={faPlus} /> Add Delivery Boy
                    </button>
                </div>
                {loading ? (
                    <div className={styles.loader}>
                        <FontAwesomeIcon icon={faSpinner} spin size="2x" />
                        <p>Loading...</p>
                    </div>
                ) : deliveryBoys.length === 0 ? (
                    <p className={styles.noResults}>No Delivery Boy found</p>
                ) : (
                    <table className={styles.table}>
                        <thead>
                            <tr>
                                <th>Full Name</th>
                                <th>Mobile Number</th>
                                <th>Actions</th> {/* Add Actions column */}
                            </tr>
                        </thead>
                        <tbody>
                            {deliveryBoys.map(boy => (
                                <tr key={boy.id}>
                                    <td>{boy.fullName}</td>
                                    <td>{boy.mobilenumber}</td>
                                    <td>
                                        <button
                                            className={styles.deleteButton}
                                            onClick={() => handleDelete(boy.id)}
                                        >
                                            <FontAwesomeIcon icon={faTrashAlt} />
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
            </div>
            {isPopupOpen && (
                <div className={styles.popup}>
                    <div className={styles.popupContent}>
                        <FontAwesomeIcon icon={faUserPlus} size="3x" className={styles.popupIcon} /> {/* Added icon */}
                        <h2>Add Delivery Boy</h2>
                        <label>Full Name</label>
                        <input
                            type="text"
                            name="fullName"
                            value={newDeliveryBoy.fullName}
                            onChange={handleChange}
                        />
                        {errors.fullName && <p className={styles.error}>{errors.fullName}</p>}
                        <label>Mobile Number</label>
                        <input
                            type="text"
                            name="mobilenumber"
                            value={newDeliveryBoy.mobilenumber}
                            onChange={handleChange}
                        />
                        {errors.mobilenumber && <p className={styles.error}>{errors.mobilenumber}</p>}
                        <button
                            className={styles.saveButton}
                            onClick={handleSave}
                            disabled={isSaving} // Disable button while saving
                        >
                            {isSaving ? (
                                <FontAwesomeIcon icon={faSpinner} spin /> // Loader icon
                            ) : (
                                'Save'
                            )}
                        </button>
                        <button className={styles.cancelButton} onClick={closePopup}>
                            Cancel
                        </button>
                    </div>
                </div>
            )}
            <Footer />
        </div>
    );
};

export default DeliveryBoy;
