import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_URL; // Replace with your API base URL

// Create a new instance of axios with default configuration
const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
});

// Add request interceptor to set common headers
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('tokenId');
    if (token) {
      config.headers['AUTH-TOKEN'] = `${token}`;
    }
    config.headers['Content-Type'] = 'application/json';
    config.headers['DEVICE-TYPE'] = 'Web';
    config.headers['VER'] = '1.0';
    config.headers['ngrok-skip-browser-warning'] = '69420';
    
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);



// Add response interceptor to handle 403 status code
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 403) {
      localStorage.removeItem('tokenId');
      window.location.href = '/';
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
