import axiosInstance from '../../api';

export const fetchMenuCategories = async (foodType, searchTerm = '') => {
  const headers = {
    'Content-Type': 'application/json',
    'DEVICE-TYPE': 'Web',
    'VER': '1.0'
  };

  const requestData = [{
    "key": "FOOD_TYPE",
    "value": foodType
  }, {
    "key": "CLIENT",
    "value": process.env.REACT_APP_CLIENT
  }];

  if (searchTerm) {
    requestData.push({
      "key": "ITEM",
      "value": searchTerm
    });
  }

  try {
    const response = await axiosInstance.post(process.env.REACT_APP_FETCH_ALL_MENU, requestData, { headers });
    return response.data;
  } catch (error) {
    console.error('Error fetching menu categories:', error);
    throw error;
  }
};
