import React from 'react';
import styles from './ContactSection1.module.css';

const ContactSection1 = () => {
  return (
    <div className={styles.aboutSection}>
      <div className={styles.overlay}></div>
      <div className={styles.text}>CONTACT US</div>
    </div>
  );
};

export default ContactSection1;
