import React from 'react';
import styles from './AboutSection1.module.css';

const AboutSection1 = () => {
  return (
    <div className={styles.aboutSection}>
      <div className={styles.overlay}></div>
      <div className={styles.text}>ABOUT US</div>
    </div>
  );
};

export default AboutSection1;
