// src/components/Footer.js
import React from 'react';
import styles from './Footer.module.css';

function Footer() {
    return (
        <footer className={styles.footer}>
            <div className={styles.fssaiContainer}>
                <p className={styles.fssaiCode}>FSSAI : 13322006000020</p>
            </div>
            <div className={styles.footerContent}>
                <div className={styles.footerSection}>
                    <h2>Contact Us</h2>
                    <p>Address-42, DLF Industrial Area, Kirti Nagar, New Delhi - 110015</p>
                    <p>+91 9289356679</p>
                    <p>+91 7610001310</p>
                </div>
                <div className={styles.footerSection}>
                    <h2>Stay Connected</h2>
                    <p>Follow us on social media channels</p>
                    <div className={styles.socialIcons}>
                        <a href="https://www.instagram.com/bawachickenofficial/" target="_blank" rel="noopener noreferrer">
                            <i className="fab fa-instagram"></i>
                        </a>
                    </div>
                </div>
                <div className={styles.footerSection}>
                    <h2>Timings</h2>
                    <p>Monday : 12:30 PM -3:30 PM & 6:00 PM-11:30 PM</p>
                    <p>Tuesday & Navaratri : OFF</p>
                    <p>Wednesday - Friday : 12:30 PM -3:30 PM & 6:00 PM-11:30 PM</p>
                    <p>Saturday - Sunday : Friday : 12:30 PM -3:30 PM & 6:00 PM-11:30 PM</p>
                </div>
            </div>
            <div className={styles.footerBottom}>
                <nav className={styles.footerNav}>
                    <a href="/">HOME</a>
                    <a href="/about">ABOUT</a>
                    <a href="/order-now">MENU</a>
                    <a href="/contact">CONTACT</a>
                    <a href="/order-now">ORDER NOW</a>
                    <a href="/terms-and-condition">TERMS & CONDITIONS</a>
                    <a href="/privacy-policy">PRIVACY POLICY</a>
                </nav>
                <p>Copyright &copy; {new Date().getFullYear()} Bawa Chicken All Rights Reserved Designed By Sparsh Mehta.</p>
            </div>
        </footer>
    );
}

export default Footer;
