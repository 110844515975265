import React from 'react';
import styles from './OrderPopup.module.css';

const OrderPopup = ({ message, handleClose }) => {
  return (
    <div className={styles.popupOverlay}>
      <div className={styles.popupContainer}>
        <p className={styles.message}>{message}</p>
        <button className={styles.okButton} onClick={handleClose}>OK</button>
      </div>
    </div>
  );
};

export default OrderPopup;
